import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
} from '@material-ui/core'
import { DefaultComponentProps } from '@material-ui/core/OverridableComponent'
import { CheckBox, CheckBoxOutlineBlank, ExpandMore, GetApp, IndeterminateCheckBox } from '@material-ui/icons'
import { UsePaginationItem } from '@material-ui/lab/Pagination'
import CustomPagination from 'components/CustomPagination/CustomPagination'
import { format } from 'date-fns'
import * as Locales from 'date-fns/locale'
import { Doc, SumBill } from 'graphql/types'
import _ from 'lodash'
import { InvoiceOrderField } from 'pages/MyInvoices/useMyInvoicesState'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import { formatCurrency } from 'utils/formatCurrency'
import { getCurrentItems } from 'utils/getCurrentItems'
import TestIDs from 'utils/TestIDs'
import { SortOrder } from 'utils/utils'

export interface InvoiceListDesktopProps {
    invoices: Doc[]
    invoicesPerPage: number
    selectedInvoices: number[]
    downloadItems: (items: number[]) => void
    items: UsePaginationItem[]
    page: number
    dateOrder: SortOrder
    docTypeOrder: SortOrder
    changeOrder: (field: InvoiceOrderField) => void
    selectAll: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectOne: (index: number) => void
    billAmount: SumBill[]
    billIsLoading: boolean
}

const InvoiceListDesktop = (props: InvoiceListDesktopProps): JSX.Element => {
    const {
        invoices,
        invoicesPerPage,
        selectedInvoices: selected,
        downloadItems,
        page,
        dateOrder,
        docTypeOrder,
        changeOrder,
        selectAll,
        selectOne,
        items,
        billAmount,
        billIsLoading,
    } = props
    const { t } = useTranslation()
    const itemMeta = getCurrentItems(page, invoicesPerPage, invoices.length)

    return (
        <Box
            width={1}
            flex={1}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
        >
            <TableContainer component={Paper}>
                <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    {...TestIDs.GET_INVOICE_LIST_TABLE('CHECKBOX_HEADER')}
                                    icon={<CheckBoxOutlineBlank style={{ color: colors.midDarkerGray }} />}
                                    checkedIcon={<CheckBox style={{ color: colors.midDarkerGray }} />}
                                    indeterminateIcon={
                                        <IndeterminateCheckBox style={{ color: colors.midDarkerGray }} />
                                    }
                                    color={'primary'}
                                    checked={invoices.length > 0 && selected.length === itemMeta.nItems}
                                    onChange={selectAll}
                                    inputProps={{ 'aria-label': 'select all invoice' }}
                                />
                            </TableCell>
                            <TableCell padding="default" style={{ minWidth: '120px' }}>
                                <TableSortLabel
                                    IconComponent={SortIcon}
                                    active={dateOrder !== undefined}
                                    direction={dateOrder}
                                    onClick={() => changeOrder('invoiceTarget')}
                                    color={'primary'}
                                >
                                    <Typography
                                        variant={'body2'}
                                        style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                    >
                                        {t('pages.myInvoices.invoiceList.date')}
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell padding="default">
                                <Typography
                                    variant={'body2'}
                                    style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                >
                                    {t('pages.myInvoices.invoiceList.billingPeriod')}
                                </Typography>
                            </TableCell>

                            <TableCell padding="default">
                                <TableSortLabel
                                    IconComponent={SortIcon}
                                    active={docTypeOrder !== undefined}
                                    direction={docTypeOrder}
                                    onClick={() => changeOrder('docType')}
                                    color={'primary'}
                                >
                                    <Typography
                                        variant={'body2'}
                                        style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                    >
                                        {t('pages.myInvoices.invoiceList.name')}
                                    </Typography>
                                </TableSortLabel>
                            </TableCell>

                            <TableCell padding="default">
                                <Typography
                                    variant={'body2'}
                                    style={{ color: colors.midDarkerGray, wordBreak: 'break-word' }}
                                >
                                    {t('pages.myInvoices.invoiceList.price')}
                                </Typography>
                            </TableCell>
                            <TableCell align="right" padding="default"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_.slice(invoices, itemMeta.start, itemMeta.end).map((row, index) => {
                            const isItemSelected = selected.includes(index)
                            const labelId = `enhanced-table-checkbox-${index}`
                            const sum = billAmount.find((v) => v.docID === row.docID)
                            return (
                                <TableRow
                                    {...TestIDs.GET_INVOICE_LIST_TABLE('CHECKBOX_ROW', 'SELECT_' + index)}
                                    hover
                                    onClick={(): void => selectOne(index)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.path + index}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            icon={<CheckBoxOutlineBlank style={{ color: colors.midDarkerGray }} />}
                                            checkedIcon={<CheckBox style={{ color: colors.midDarkerGray }} />}
                                            color={'primary'}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </TableCell>
                                    <TableCell padding="default">
                                        <Typography>
                                            {row.invoiceTarget !== ''
                                                ? format(new Date(row.invoiceTarget), 'dd.MM.yy')
                                                : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {row.invoiceTarget !== ''
                                                ? format(new Date(row.invoiceTarget), 'MMMM yyyy', {
                                                      locale: Locales.de,
                                                  })
                                                : ''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{ wordBreak: 'break-word' }}>
                                            {t('docType.' + row.docType)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        {billIsLoading && <CircularProgress size={20} />}
                                        {!billIsLoading && (
                                            <Typography>{sum ? formatCurrency(sum.sumTotal.brutto) : ''}</Typography>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            {...TestIDs.GET_INVOICE_LIST_TABLE('PDF_BUTTON', 'DOWNLOAD_' + index)}
                                            startIcon={<GetApp />}
                                            color="primary"
                                            variant="outlined"
                                            style={{ border: '2px solid', maxWidth: 10 }}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                downloadItems([index])
                                            }}
                                        >
                                            PDF
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                {...TestIDs.GET_INVOICE_LIST_TABLE('DOWNLOAD_ALL_BUTTON')}
                disabled={selected.length === 0}
                variant={'contained'}
                color={'primary'}
                style={{ marginTop: '16px', marginBottom: '8px', alignSelf: 'flex-end' }}
                onClick={() => downloadItems(selected)}
            >
                {t('pages.myInvoices.invoiceList.download')}
            </Button>
            <CustomPagination items={items} />
        </Box>
    )
}

export default InvoiceListDesktop

//NOTE: to be deleted or moved
export const SortIcon = (props: DefaultComponentProps<any>): JSX.Element => (
    <ExpandMore viewBox={'3 3 18 18'} {...props} />
)
