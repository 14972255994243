const colors = {
    appBarBackground: '#F8F8F8',
    lightShadow: '#F0F0F0',
    black: '#000000',
    button: '#55afc8',
    darkGray: '#D5B076',
    focusedField: '#913DD8',
    main: '#d6b47a',
    lightMain: '#D5B076',
    lighterMain: '#D5B076',
    lightBorderGray: '#D5B076',
    lightGray: '#F3F0F0',
    lightDarkerGray: '#BFBFBF',
    darkIcon: '#263238',
    labelColor: '#929292',
    lightWarning: 'rgba(255, 0, 0, 0.20)',
    midGray: '#D5B076',
    midDarkerGray: '#737373',
    warning: '#FF0000',
    white: '#FFFFFF',
}

export default colors
